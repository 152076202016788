import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgWhiteLogo from '../generated/svg/white-logo'
import { pageMaxWidth } from '../styles/page-max-width'
import { Text } from './text'
import { Mbr } from './br'

const Outer = styled.div`
  background-color: var(--blue);
`

const Inner = styled.div`
  ${pageMaxWidth}
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 40rem){
    padding-top: 5rem;
    align-items: flex-start;
  }
`

const Logo = styled.a`
  svg {
    display: block;
    height: 3.1875rem;
    width: auto;
    @media (max-width: 40rem){
      height: 3.4375rem;
    }
  }
`

const NavContainer = styled.div`
  margin-top: 2rem;
`

const Nav = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 40rem){
    flex-direction: column;
  }
`

const NavItem = styled.a`
  display: block;
  font-size: 0.75rem;
  line-height: 2.3333333333333335;
  color: white;
  font-weight: 500;
  padding: 0 1.5rem;
  position: relative;
  box-sizing: border-box;
  white-space: nowrap;
  @media (max-width: 60rem){
    ${Nav}:nth-child(1) > &:nth-child(3) {
      flex: 0 0 100%;
      text-align: center;
      &::before {
        display: none;
      }
    }
  }
  @media (max-width: 40rem){
    font-size: 0.8125rem;
    line-height: 2.3846153846153846;
    padding: 0;
  }
  & + & {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: calc((1lh - 1em) / 2);
      height: 1em;
      width: 1px;
      background-color: currentColor;
      @media (max-width: 40rem){
        display: none;
      }
    }
  }
`

const Copyright = styled(Text)`
  font-size: 0.625rem;
  line-height: 2.8;
  font-weight: 500;
  color: white;
  margin-top: 1.4375rem;
  @media (max-width: 40rem){
    font-size: 0.6875rem;
    line-height: 1.7272727272727273;
  }
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
    <Inner>
      <Logo>
        <SvgWhiteLogo />
      </Logo>
      <NavContainer>
        <Nav>
          <NavItem href="https://www.diners.co.jp/ja/index.html" target="_blank">
            ダイナースクラブ ウェブサイト
        </NavItem>
          <NavItem href="https://www.diners.co.jp/ja/privacy.html" target="_blank">
            個人情報保護に関するご案内
        </NavItem>
          <NavItem href="https://www.diners.co.jp/ja/privacy_law.html" target="_blank">
            「個人情報の保護に関する法律」に基づく公表事項
        </NavItem>
        </Nav>
        <Nav>
          <NavItem href="https://helpfeel.com/cards-faq-custhelp/" target="_blank">
            ダイナースクラブカードのQ&Aはこちら
        </NavItem>
        </Nav>
        </NavContainer>
      <Copyright>Copyright © <Mbr />SUMITOMO MITSUI TRUST CLUB CO., LTD. All rights reserved.</Copyright>
    </Inner>
  </Outer>
}
